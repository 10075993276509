import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@/components/BlockContent';
import BlockContentContainer from '@/components/BlockContentContainer';
import DivisionReferenceCard from '@/components/DivisionReferenceCard';
import MasonryGrid from '@/components/MasonryGrid';
import Seo from '@/components/Seo';
import StoryCard from '@/components/StoryCard';
import Layout from '@/layouts/default';

type IndexPageProps = {
  data: GatsbyTypes.HomePageQuery;
};

const IndexPage: React.FC<IndexPageProps> = ({
  data: { homepage, primaryDivisions, stories },
}) => {
  let blocks = homepage?._rawBody ?? ([] as Array<any>);
  let primaryBlocks = [];

  if (blocks.length && blocks[0]._type === 'header') {
    primaryBlocks = blocks.slice(0, 1);
    blocks = blocks.slice(1);
  }

  const renderGrid = useMemo(
    () =>
      stories.edges.map(({ node }, i) => (
        <StoryCard key={`${i}-${node.id}`} story={node} readingTime={false} />
      )),
    [stories],
  );

  return (
    <Layout>
      <Seo
        title={homepage?.meta?.title ?? 'Ahead of innovation'}
        description={homepage?.meta?.description}
      />
      {primaryBlocks.length > 0 && (
        <BlockContentContainer>
          <BlockContent blocks={primaryBlocks} isOnlyContent={true} />
        </BlockContentContainer>
      )}
      {Boolean(primaryDivisions?.nodes?.length) && (
        <MasonryGrid className="-mt-5xl-fluid">
          {primaryDivisions?.nodes.map((node) => (
            <DivisionReferenceCard key={node.id} division={node} />
          ))}
        </MasonryGrid>
      )}
      {blocks.length > 1 && (
        <BlockContentContainer>
          <BlockContent blocks={blocks} isOnlyContent={false} />
        </BlockContentContainer>
      )}
      <MasonryGrid>{renderGrid}</MasonryGrid>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
    homepage: sanityPage(
      purpose: { eq: "homepage" }
      i18n_lang: { eq: $language }
    ) {
      title
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    primaryDivisions: allSanityDivision(
      filter: { i18n_lang: { eq: $language }, key: { regex: "/[^duotec]/" } }
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        ...DivisionReferenceCardFields
      }
    }
    stories: allSanityStory(
      filter: {
        i18n_lang: { eq: $language }
        hasParentItem: { eq: false }
        hideTile: { ne: true }
      }
      sort: { fields: priority, order: DESC }
    ) {
      edges {
        node {
          ...StoryCardFields
        }
      }
    }
  }
`;
