import React from 'react';
import Border from '@/components/Border';
import Card, { CardProps } from '@/components/Card';

export interface DivisionCardProps {
  division?: string;
}

const DivisionCard: React.FC<
  DivisionCardProps & CardProps & React.HTMLAttributes<HTMLDivElement>
> = ({ division, children, ...any }) => {
  return (
    <Card {...any}>
      <div className="flex items-center mb-auto overflow-x-hidden">
        <Border className="flex-1 transition-all duration-500 ease-out transform-gpu group-hover:translate-x-2xs" />
      </div>
      {children}
    </Card>
  );
};

export default DivisionCard;
