import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CardProps } from '@/components/Card';
import Cover from '@/components/Cover';
import DivisionCard from '@/components/DivisionCard';
import Link from '@/components/Link';
import { Routes } from '@/enums/routes.enum.js';
import { replacer } from '@/helpers/string.js';

export interface DivisionReferenceCardProps {
  division: GatsbyTypes.DivisionReferenceCardFieldsFragment;
  eyebrow?: string;
  large?: boolean;
}

const DivisionReferenceCard: React.FC<
  DivisionReferenceCardProps &
    Pick<CardProps, 'media'> &
    React.HTMLAttributes<HTMLDivElement>
> = ({ division, eyebrow, large = false, className, ...any }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const link = replacer(Routes.Division, division);
  const { t } = useTranslation();

  const { title, description } = division;

  return (
    <DivisionCard
      el="article"
      linkRef={linkRef}
      division={division.key}
      className={`group min-h-md text-sm leading-snug md:text-base md:leading-snug lg:min-h-lg xl:text-lg xl:leading-snug xl:min-h-0 xl:aspect-[5/4] 3xl:text-xl 3xl:leading-snug ${
        className ?? ''
      } ${large ? 'sm:min-h-2xl lg:min-h-3xl' : ''}`}
      contentClassName="flex flex-col items-stretch justify-end"
      media={
        division.mainMedia?.asset ? (
          <Cover
            className="z-0 w-full h-full"
            width={large ? 1600 : 800}
            height={large ? 900 : 450}
            media={division.mainMedia as GatsbyTypes.SanityCover}
          />
        ) : undefined
      }
      {...any}
    >
      <div
        className={`mb-0 mt-xl-fluid ${
          large
            ? 'sm:flex-1 sm:flex sm:flex-col sm:items-start sm:justify-center sm:text-4xl-fluid sm:mb-base-fluid md:mb-base-fluid'
            : ''
        }`}
      >
        {eyebrow && (
          <div className="text-sm font-semibold leading-tight tracking-wide text-blue-200 mb-sm md:text-base xl:mb-base xl:text-lg">
            {eyebrow}
          </div>
        )}
        <h2
          className={`font-semibold text-2xl max-w-prose-2xs mt-0 mb-sm leading-tight ${
            large
              ? 'sm:font-light sm:text-4xl-fluid sm:leading-none'
              : 'sm:text-lg sm:leading-tight md:text-2xl md:leading-tight lg:text-2xl lg:leading-tight 3xl:text-3xl 3xl:leading-tight'
          }`}
        >
          <Link
            className="inline-block rounded text-inherit hover:no-underline"
            to={link}
            ref={linkRef}
          >
            {title ?? description}
          </Link>
        </h2>
      </div>
      <p className="my-0 text-inherit max-w-prose-sm">{t('Learn more')}</p>
    </DivisionCard>
  );
};

export default DivisionReferenceCard;
