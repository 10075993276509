import React, { useRef } from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { CardProps } from '@/components/Card';
import Cover from '@/components/Cover';
import DivisionCard from '@/components/DivisionCard';
import Link from '@/components/Link';
import { Routes } from '@/enums/routes.enum.js';
import { replacer } from '@/helpers/string.js';
import LinkReference from './LinkReference';

export interface StoryCardProps {
  story: GatsbyTypes.StoryCardFieldsFragment;
  eyebrow?: string;
  large?: boolean;
  readingTime?: boolean;
}

const StoryCard: React.FC<
  StoryCardProps &
    Pick<CardProps, 'media'> &
    React.HTMLAttributes<HTMLDivElement>
> = ({
  story,
  eyebrow,
  large = false,
  readingTime = true,
  className,
  ...any
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation();
  let link;

  const {
    title,
    summary,
    readingTime: readingTimeValue,
    division,
    linkToPage,
    reference,
  } = story;

  if (!linkToPage) {
    link = replacer(Routes.Story, story);
  }

  const eyebrowText = eyebrow ?? story.eyebrow;

  return (
    <DivisionCard
      el="article"
      linkRef={linkRef}
      division={division?.key}
      className={`group min-h-xl text-sm leading-snug sm:min-h-lg md:text-base md:leading-snug lg:min-h-lg xl:text-lg xl:leading-snug xl:min-h-xl 3xl:text-xl 3xl:leading-snug ${
        className ?? ''
      } ${large ? 'sm:min-h-2xl lg:min-h-3xl' : ''}`}
      contentClassName="flex flex-col items-stretch justify-end"
      media={
        story.mainMedia?.asset ? (
          <Cover
            className="z-0 w-full h-full"
            width={large ? 1600 : 800}
            height={large ? 900 : 450}
            media={story.mainMedia as GatsbyTypes.SanityCover}
          />
        ) : undefined
      }
      {...any}
    >
      <div
        className={`mb-0 mt-xl-fluid ${
          large
            ? 'sm:flex-1 sm:flex sm:flex-col sm:items-start sm:justify-center sm:text-4xl-fluid sm:mb-base-fluid md:mb-base-fluid'
            : ''
        }`}
      >
        {eyebrowText && (
          <div className="text-sm font-semibold leading-tight tracking-wide text-blue-200 mb-sm md:text-base xl:mb-base xl:text-lg">
            {eyebrowText}
          </div>
        )}
        <h2
          className={`font-semibold text-2xl max-w-prose-2xs mt-0 mb-sm leading-tight ${
            large
              ? 'sm:font-light sm:text-4xl-fluid sm:leading-none'
              : 'sm:text-lg sm:leading-tight md:text-2xl md:leading-tight lg:text-2xl lg:leading-tight 3xl:text-3xl 3xl:leading-tight'
          }`}
        >
          {linkToPage && reference ? (
            <LinkReference
              className="inline-block rounded text-inherit hover:no-underline"
              reference={reference}
              ref={linkRef}
            >
              {title}
            </LinkReference>
          ) : (
            <Link
              className="inline-block rounded text-inherit hover:no-underline"
              to={link}
              ref={linkRef}
            >
              {title}
            </Link>
          )}
        </h2>
      </div>
      {summary && <p className="my-0 text-inherit max-w-prose-sm">{summary}</p>}
      {readingTime && !linkToPage && readingTimeValue && (
        <div
          title={t('Reading time')}
          className="text-sm font-normal opacity-50 mt-sm-fluid lg:-mb-sm"
        >
          <span className="sr-only">
            <Trans>Reading time</Trans>:{' '}
          </span>
          <Trans
            count={Math.ceil(Math.max(1, readingTimeValue.minutes ?? 0))}
            i18nKey="readingTime"
          />
        </div>
      )}
    </DivisionCard>
  );
};

export default StoryCard;
